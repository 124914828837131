<template>

  <div>
    <!-- Table Container Card -->
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="updateSite"
              v-model="siteData"
              :clearable="false"
              >
              <template #selected-option="{ site_name }">
                <span v-if="site_name.length < 18">{{site_name}}</span>
                <span v-if="site_name.length >= 18">{{site_name.substring(0, 18) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">

            <b-form-select v-model="role" @change="updateRole()">

                <b-form-select-option value="" >Account Type</b-form-select-option>
                <b-form-select-option :value="role._id" v-for="role in roles" :key="role._id">{{role.name}}</b-form-select-option>
              </b-form-select>
                
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">

            <b-form-select v-model="crew" @change="filterTable">

                <!-- <b-form-select-option value="">Select User</b-form-select-option>
                <b-form-select-option :value="crew._id" v-for="crew in users" :key="crew._id">{{crew.full_name}}</b-form-select-option> -->

                <b-form-select-option value=''>Select</b-form-select-option>
                                
                  <b-form-select-option-group :label="getCapitalize(role.label)" v-for="(role,i) in users" :key="role.label">

                      <b-form-select-option :value="user._id" v-for="user in role.options" :key="user._id">{{user.full_name}}</b-form-select-option>

                  </b-form-select-option-group>
              </b-form-select>
                
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <!-- <label>Status</label> -->
            <b-form-select v-model="shift" @change="filterTable">

              <b-form-select-option value="">Select Shift</b-form-select-option>
              <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift}}</b-form-select-option>
            </b-form-select>
          </b-col>


        </b-row>

        <b-row class="mt-1 tab_mobile_margin_top_zero">

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_margin_bottom_point_five"
          >

            <div class="demo-inline-spacing tab_mobile_margin_bottom_zero">
              <b-form-radio
                class="mb-0 mt-1"
                @change="updateDateType"
                name="some-radios"
                value="start_end"
                v-model="dateFilter"
              >
                Start & End
              </b-form-radio>

              <b-form-radio
                class="mb-0 mt-1 tab_mobile_margin_bottom_zero"
                @change="updateDateType"
                name="some-radios"
                value="month_year"
                v-model="dateFilter"
              >
                Month & Year
              </b-form-radio>
            </div>

          </b-col>
          
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five"
            v-if="dateFilter == 'month_year'"
            >
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="months"
              :clearable="false"
              class="w-100"
              placeholder="Month"
              @input="getDate()"
              v-model="month"

            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex tab_mobile_margin_bottom_zero"
            v-if="dateFilter == 'month_year'"
            >
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="yearData"
              class="w-100"
              placeholder="Year"
              @input="getDate()"
              v-model="year"
              :clearable="false"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five"
            v-if="dateFilter == 'start_end'"
            >
            <b-form-datepicker
                  
            v-model="start"
            
            :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
            locale="en" @input="updateStart()"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex tab_mobile_margin_bottom_zero"
            v-if="dateFilter == 'start_end'"
            >

            <b-form-datepicker
                  
              v-model="end"
              
              :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
              locale="en" @input="filterTable" :min="disabledDates()"
            />

          </b-col>

          <!-- <b-col
            cols="12"
            md="3"
            class="mt-0"
            >

            <b-button
                variant="danger"
                class="mt-0"
                @click="resetall"
              >
              <span class="text-nowrap">Reset</span>
            </b-button>

          </b-col> -->

        </b-row>


      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top1 -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >

          <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">

              <!-- <div>
                <b-button
                  v-if="siteData != null && siteData != '' && siteData._id != 'all-site' && dateFilter == 'month_year'" variant="info" class="mr-1" @click="download('pdf')">
                  <span class="text-nowrap">PDF</span>
                </b-button>
              </div> -->

              <!-- <div v-if="siteData != null && siteData != '' && siteData.site_name == 'Qutubuddin' && dateFilter == 'month_year'"> -->
              <!-- <div v-if="siteData != null && siteData != '' && siteData.site_name == 'Novena Square 2' && dateFilter == 'month_year'"> -->
                
              <b-button variant="warning" class="mr-1" @click="shortfallTest()" v-if="(siteData != null && siteData._id != 'all-site') && dateFilter == 'month_year' && checkPermission($route.name, 'Shortfall Report')">
                <span class="text-nowrap">Shortfall Report</span>
              </b-button>
                
              <b-button
                v-if="siteData != null && siteData != '' && siteData._id != 'all-site' && dateFilter == 'month_year' && checkPermission($route.name, 'Excel')" variant="info" class="mr-1" @click="excelTest()">
                <span class="text-nowrap">Excel</span>
              </b-button>

              <b-button
                variant="success"
                class="mr-1 mobile-margin-bottom"
                v-if="(siteData != null && siteData != '' && siteData._id != 'all-site') && (checkPermission($route.name, 'Create Attendance'))"
              >
                <span class="text-nowrap" @click="openModel()">Create Attendance</span>
              </b-button>

              <b-button
                variant="warning"
                class="mr-1 mobile-margin-bottom"
                v-if="(siteData != null && siteData != '' && siteData._id != 'all-site' && this.items.length > 0) && (checkPermission($route.name, 'Export Pdf'))"
              >
                <span class="text-nowrap" @click="exportPdfModel()">Export PDF</span>
              </b-button>

              <b-button
                variant="danger"
                class="mr-1 mobile-margin-bottom"
                v-if="(siteData != null && siteData != '' && siteData._id != 'all-site') && (checkPermission($route.name, 'Export Pdf'))"
              >
                <span class="text-nowrap" @click="openExportModel">Export Individual</span>
              </b-button>

              <b-button
                :variant="update == 'disabled' ? 'primary' : 'secondary'"
                class="mr-1 mobile-margin-bottom"
                v-if="(siteData != null && siteData != '' && siteData._id != 'all-site') && (checkPermission($route.name, 'Enable Status Update'))"
                @click="changeUpdate"
              >
                <span class="text-nowrap" v-if="update == 'disabled'">Enable Update</span>
                <span class="text-nowrap" v-if="update == 'enabled'">Disable Update</span>
              </b-button>

            </div>
            
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        :class="(siteData != null && siteData.attendance_mode != 'camera' ? 'position-relative attendanceTableGroup' :'position-relative attendanceTable') && items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
        :items="dataList"
        responsive
        :fields="siteData != null && siteData.attendance_mode != 'camera' ? tableColumnsGroup :tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        class="common_big_tbl"
        :sort-desc.sync="isSortDirDesc"
      >


        <!-- Column: User -->

          <template #cell(info)="items">             
							<feather-icon
							:icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
							@click="items.toggleDetails"
							/>
				</template>

        <template #cell(site)="items">
           
            <span class="wordBreak">
              <div class="my-1" @click="items.toggleDetails">{{ items.item.site.site_name}}</div>
            </span>
         
        </template>

        <template #cell(full_name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.user.full_name | capitalize}}</span>
         
        </template>

        <template #cell(role)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.user.role | capitalize}}</span>
         
        </template>

        <template #cell(shift)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.shift ? items.item.shift.shift : 'N/A'}}</span>
         
        </template>

        <!-- <template #cell(day_count)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.day_count }}</span>
         
        </template> -->

        <template #cell(status_id)="items">
          <div v-if="update == 'disabled'">
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.attendance_status | capitalize}}</span>
            
          </div>
          <div v-else>
            
            <!-- <v-select
              :ref="'vSelect' + items.index"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="attendance_statuses"
              label="title"
              class="w-100"
              placeholder="Select Status"
              v-model="items.item.status_id"
              @input="changeStatusReset(items.index)"
              :clearable=false
              :appendToBody=true

            /> -->

            <b-form-select v-model="items.item.status_id" @change="changeStatusReset(items.index,$event)">
              
              <b-form-select-option :value="status._id" v-for="(status,i) in attendance_statuses" :key="'status._id' + '' +i + ''+ items.index">{{status.title}}</b-form-select-option>
              
            </b-form-select>

          </div>
         
        </template>



        <template #cell(date)="items">
           
           <span class="wordBreak" @click="items.toggleDetails">{{ sitedateTime2(items.item.date,siteData.pdf_date_format,'')}}</span>
            
         
        </template>

        <template #cell(group)="items">
           
          <div v-if="update == 'disabled'">

            <span class="wordBreak" @click="items.toggleDetails">{{items.item.generate_group == 'dynamic' ? 'Auto Generate' : items.item.group}}</span>

          </div>
          <div v-else>

            <div class="demo-inline-spacing">
                <b-form-radio
                  class="mb-0 mt-0"
                  :name="'some-radios9' + items.index"
                  value="dynamic"
                  v-model="items.item.generate_group"
                  @change="items.item.group = '';updateTaskGroup(items.item);"
                >
                  Auto Generate
                </b-form-radio>

                <b-form-radio
                  class="mb-0 mt-0"
                  :name="'some-radios9' + items.index"
                  value="custom"
                  v-model="items.item.generate_group"
                  @change="items.item.group = '';updateTaskGroup(items.item);"
                >
                  Custom
                </b-form-radio>
            </div>

            <b-form-input class="mt-1" placeholder="Task Group Names" v-model="items.item.group" autocomplete="off" v-if="items.item.generate_group == 'custom'" @change="updateTaskGroup(items.item)" />
          </div>
            
         
        </template>

        <!-- <template #cell(punch_in)="items">
          
          <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
            <div v-if="checkPermission($route.name, 'Edit Punch In Time') && update == 'enabled'">
              
              <date-picker v-model="items.item.punch_in" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              
              valueType="format"
              @open="curr_user_punch_in = items.item.date"
              @confirm = "calHour(items.index,'punch_in')"
              @change = "calHour(items.index,'punch_in')"
              :disabled-date="disabledDate"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              ></date-picker>

            </div>
            <div v-else>
              <span class="wordBreak">{{sitedateTime2(items.item.punch_in,siteData.pdf_date_format,siteData.pdf_time_format)}}
              </span>
            </div>
          </div>
          <div v-else>
            <span class="wordBreak">{{'N/A'}}</span>
          </div>

        </template> -->

        <!-- <template #cell(punch_in_mode)="items"> -->
           
            <!-- <p class="wordBreak" v-if="items.item.punch_in_mode != 'nfc'">{{ items.item.punch_in_mode | capitalize}}</p>
            <p class="wordBreak" v-else>{{ 'NFC'}}</p> -->

            <!-- <div>
              <span class="wordBreak" v-if="items.item.punch_in_mode == 'nfc'">{{ 'NFC' }}</span>
              <span class="wordBreak" v-else-if="items.item.punch_in_mode == 'gps'">{{ 'GPS' }}</span>
              
              <span class="wordBreak" v-else>{{ items.item.punch_in_mode | capitalize}}</span>
            </div> -->
         
        <!-- </template> -->


        <!-- <template #cell(punch_in_image)="items"> -->

          <!-- <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">

            <div v-if="checkPermission($route.name, 'View Punch In Image')">
              
              <div v-if="items.item.punch_in_image_thumbnail == null && items.item.punch_in != null">
                <b-button
                size="sm"
                variant="warning"
                class="ml-2"
                v-if="items.item.punch_in_image != null"
                
                @click="openView(items.item.punch_in_image,'Punch In Image')"
                >
                  <feather-icon icon="EyeIcon"/>
                </b-button>
              </div>
              
              <div v-if="items.item.punch_in_image != null && items.item.punch_in_image_thumbnail != null && items.item.punch_in != null">
                <img :src="items.item.punch_in_image_thumbnail" @click="openView(items.item.punch_in_image,'Punch In Image')">
              </div>


              <span v-if="items.item.punch_in != null && items.item.punch_in_image == null"><p class="wordBreak">N/A</p></span>
            </div>
            <div v-else><span class="wordBreak">N/A</span></div>
          </div>
          <div v-else>
            <span class="wordBreak">{{'N/A'}}</span>
          </div> -->

         
        <!-- </template> -->

        <!-- <template #cell(punch_in_location)="items">

          <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
           
            
            <div v-if="checkPermission($route.name, 'View Punch In Location')">
              <b-button
              size="sm"
              variant="primary"
              class="ml-0"
              v-if="items.item.punch_in_lat != null && items.item.punch_in_long != null"
              @click="openLocation(items.item,'in')"
              >
                <feather-icon icon="MapPinIcon"/>
              </b-button>

              <span v-if="items.item.punch_in != null && items.item.punch_in_lat == null && items.item.punch_in_long == null"><p class="wordBreak">N/A</p></span>
            </div>
            <div v-else><span class="wordBreak">N/A</span></div>
          </div>
          <div v-else>
            <span class="wordBreak">{{'N/A'}}</span>
          </div>
        </template> -->

        <!-- <template #cell(punch_out)="items"> -->

          <!-- <div v-if="items.item.status_id && items.item.attendance_status == 'Present'"> -->
           
            <!-- <div v-if="checkPermission($route.name, 'Edit Punch Out Time') && update == 'enabled'">
             <date-picker 
              v-model="items.item.punch_out" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              @open="curr_user_punch_in = items.item.punch_in"
              :disabled-date="disabledDate2"
              :disabled-time="disabledDate2"
              valueType="format"
              @confirm = "calHour(items.index,'punch_out')"
              @change = "calHour(items.index,'punch_out')"
              :clearable=false
              :confirm=true
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              ></date-picker>
            </div> -->
            <!-- <div v-else> -->
              <!-- {{items.item.punch_out | dateTimeSec}} -->
              <!-- <span class="wordBreak">{{sitedateTime2(items.item.punch_out,siteData.pdf_date_format,siteData.pdf_time_format)}}
              </span>
              </div> 
          </div> -->
          <!-- <div v-else>
            <span class="wordBreak">{{'N/A'}}</span>
          </div> -->

         
        <!-- </template> -->

        <!-- <template #cell(punch_out_mode)="items"> -->
           
            <!-- <p class="wordBreak">{{ items.item.punch_out_mode | capitalize}}</p> -->
            
            <!-- <div v-if="items.item.punch_in != null && items.item.punch_out == null">
            </div>
            <div v-else>
              <span class="wordBreak" v-if="items.item.punch_out_mode == 'nfc'">{{ 'NFC' }}</span>
              <span class="wordBreak" v-else-if="items.item.punch_out_mode == 'gps'">{{ 'GPS' }}</span>
              
              <span class="wordBreak" v-else>{{ items.item.punch_out_mode | capitalize}}</span>
            </div> -->
            
         
        <!-- </template> -->

        <!-- <template #cell(punch_out_image)="items"> -->

          <!-- <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
            <div v-if="checkPermission($route.name, 'View Punch Out Image')">
              

              <div v-if="items.item.punch_out_image_thumbnail == null && items.item.punch_out != null">
                <b-button
                size="sm"
                variant="warning"
                class="ml-2"
                v-if="items.item.punch_out_image != null"
                
                @click="openView(items.item.punch_in_image,'Punch Out Image')"
                >
                  <feather-icon icon="EyeIcon"/>
                </b-button>
              </div>
              
              <div v-if="items.item.punch_out_image != null && items.item.punch_out_image_thumbnail != null && items.item.punch_out != null">
                <img :src="items.item.punch_out_image_thumbnail" align="center" @click="openView(items.item.punch_out_image,'Punch Out Image')">  
              </div>

              <span v-if="items.item.punch_out != null && items.item.punch_out_image == null"><p class="wordBreak">N/A</p> </span>
            </div>
            <div v-else><span class="wordBreak">N/A</span></div>
          </div>
          <div v-else>
            <span class="wordBreak">{{'N/A'}}</span>
          </div> -->

        
        <!-- </template> -->

        <!-- <template #cell(punch_out_location)="items">
          <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
            <div v-if="checkPermission($route.name, 'View Punch Out Location')">
              <b-button
              size="sm"
              variant="primary"
              class="ml-0"
              v-if="items.item.punch_out_lat != null && items.item.punch_out_long != null"
              @click="openLocation(items.item,'out')"
              >
                <feather-icon icon="MapPinIcon"/>
              </b-button>
             
              <span v-if="items.item.punch_out != null && items.item.punch_out_lat == null && items.item.punch_out_long == null"><p class="wordBreak">N/A</p></span> -->

              <!-- <p class="wordBreak">{{ items.item.item.punch_out_facial | capitalize}}</p> -->
            <!-- </div>
            <div v-else><span class="wordBreak">N/A</span></div>
          </div>
          <div v-else>
            <span class="wordBreak">{{'N/A'}}</span>
          </div>

         
        </template> -->

        <!-- <template #cell(total_hour)="items">

          <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
           
            <span class="wordBreak">{{items.item.total_hour}}</span>
          </div>
          <div v-else>
            <span class="wordBreak">{{'00:00'}}</span>
          </div>
         
        </template> -->

        <!-- <template #cell(ot_rate)="items">

          <div v-if="update == 'disabled'">

            <span class="wordBreak">S$ {{items.item.ot_rate}}</span>
          </div>
          <div v-else>
            <b-form-input placeholder="" v-model="items.item.ot_rate" @change="updateOTRate(items.index)" autocomplete="off" />
          </div>
        </template> -->

        <!-- <template #cell(remarks)="items">

          <span class="wordBreak">{{items.item.remarks | capitalize}}</span>
          
        </template> -->

        

        <template #cell(actions)="items">

            <b-link
              v-if="checkPermission($route.name, 'Delete')"
              v-b-tooltip.hover.v-warning
              title="Delete"
              variant="outline-warning"
              @click="deleteItem(items.item._id)"
            >
                <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                
            </b-link>
          
            <b-link
              v-if="items.item.punch_out != null"
              v-b-tooltip.hover.v-warning
              title="Remove Punch out"
              variant="outline-warning"
              @click="removePunchOutItem(items.item._id)"
            >
                <feather-icon icon="UserMinusIcon" class="mr-1 mediumSize"/>
                
            </b-link>

        </template>

        <template #row-details="items">
						<div class="p-1 px-2 fbDetail mb-1">
							<b-row>

							<b-col cols="12" xl="12" >
								<table class="mt-2 mt-xl-0 w-100">
									<tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch In Time
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
                          <div v-if="checkPermission($route.name, 'Edit Punch In Time') && update == 'enabled'">
                            
                            <date-picker v-model="items.item.punch_in" 
                            format="DD MMM YYYY HH:mm" 
                            type="datetime"
                            placeholder="Select"
                            
                            valueType="format"
                            @open="curr_user_punch_in = items.item.date"
                            @confirm = "calHour(items.index,'punch_in')"
                            @change = "calHour(items.index,'punch_in')"
                            :disabled-date="disabledDate"
                            :clearable=false
                            :confirm=true
                            lang="en"
                            :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                            ></date-picker>

                          </div>
                          <div v-else>
                            <span class="wordBreak">{{sitedateTime2(items.item.punch_in,siteData.pdf_date_format,siteData.pdf_time_format)}}
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <span class="wordBreak">{{'N/A'}}</span>
                        </div>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch Out Time
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                     <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
                      
                        <div v-if="checkPermission($route.name, 'Edit Punch Out Time') && update == 'enabled'">
                        <date-picker 
                          v-model="items.item.punch_out" 
                          format="DD MMM YYYY HH:mm" 
                          type="datetime"
                          placeholder="Select"
                          @open="curr_user_punch_in = items.item.punch_in"
                          :disabled-date="disabledDate2"
                          :disabled-time="disabledDate2"
                          valueType="format"
                          @confirm = "calHour(items.index,'punch_out')"
                          @change = "calHour(items.index,'punch_out')"
                          :clearable=false
                          :confirm=true
                          :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                          ></date-picker>
                        </div>
                        <div v-else><!-- {{items.item.punch_out | dateTimeSec}} -->
                          <span class="wordBreak">{{sitedateTime2(items.item.punch_out,siteData.pdf_date_format,siteData.pdf_time_format)}}
                          </span>
                          </div> 
                      </div>
                      <div v-else>
                        <span class="wordBreak">{{'N/A'}}</span>
                      </div>
										</td>

                    	<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch In Mode
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                     <div>
                        <span class="wordBreak" v-if="items.item.punch_in_mode == 'nfc'">{{ 'NFC' }}</span>
                        <span class="wordBreak" v-else-if="items.item.punch_in_mode == 'gps'">{{ 'GPS' }}</span>
                        
                        <span class="wordBreak" v-else>{{ items.item.punch_in_mode | capitalize}}</span>
                      </div>
										</td>

									</tr>
                  
                  <tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch Out Mode
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <div v-if="items.item.punch_in != null && items.item.punch_out == null">
                      </div>
                      <div v-else>
                        <span class="wordBreak" v-if="items.item.punch_out_mode == 'nfc'">{{ 'NFC' }}</span>
                        <span class="wordBreak" v-else-if="items.item.punch_out_mode == 'gps'">{{ 'GPS' }}</span>
                        
                        <span class="wordBreak" v-else>{{ items.item.punch_out_mode | capitalize}}</span>
                      </div>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch In Image
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                         <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">

                            <div v-if="checkPermission($route.name, 'View Punch In Image')">
                              
                              <div v-if="items.item.punch_in_image_thumbnail == null && items.item.punch_in != null">
                                <b-button
                                size="sm"
                                variant="warning"
                                class="ml-2"
                                v-if="items.item.punch_in_image != null"
                                
                                @click="openView(items.item.punch_in_image,'Punch In Image')"
                                >
                                  <feather-icon icon="EyeIcon"/>
                                </b-button>
                              </div>
                              
                              <div v-if="items.item.punch_in_image != null && items.item.punch_in_image_thumbnail != null && items.item.punch_in != null">
                                <img :src="items.item.punch_in_image_thumbnail" @click="openView(items.item.punch_in_image,'Punch In Image')">
                              </div>


                              <span v-if="items.item.punch_in != null && items.item.punch_in_image == null"><p class="wordBreak">N/A</p></span>
                            </div>
                            <div v-else><span class="wordBreak">N/A</span></div>
                          </div>
                          <div v-else>
                            <span class="wordBreak">{{'N/A'}}</span>
                          </div>
										</td>

                    	<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch Out Image
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
                          <div v-if="checkPermission($route.name, 'View Punch Out Image')">
                            

                            <div v-if="items.item.punch_out_image_thumbnail == null && items.item.punch_out != null">
                              <b-button
                              size="sm"
                              variant="warning"
                              class="ml-2"
                              v-if="items.item.punch_out_image != null"
                              
                              @click="openView(items.item.punch_in_image,'Punch Out Image')"
                              >
                                <feather-icon icon="EyeIcon"/>
                              </b-button>
                            </div>
                            
                            <div v-if="items.item.punch_out_image != null && items.item.punch_out_image_thumbnail != null && items.item.punch_out != null">
                              <img :src="items.item.punch_out_image_thumbnail" align="center" @click="openView(items.item.punch_out_image,'Punch Out Image')">  
                            </div>

                            <span v-if="items.item.punch_out != null && items.item.punch_out_image == null"><p class="wordBreak">N/A</p> </span>
                          </div>
                          <div v-else><span class="wordBreak">N/A</span></div>
                        </div>
                        <div v-else>
                          <span class="wordBreak">{{'N/A'}}</span>
                        </div>
										</td>

									</tr>

                  <tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch In Location
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
           
            
                        <div v-if="checkPermission($route.name, 'View Punch In Location')">
                          <b-button
                          size="sm"
                          variant="primary"
                          class="ml-0"
                          v-if="items.item.punch_in_lat != null && items.item.punch_in_long != null"
                          @click="openLocation(items.item,'in')"
                          >
                            <feather-icon icon="MapPinIcon"/>
                          </b-button>

                          <span v-if="items.item.punch_in != null && items.item.punch_in_lat == null && items.item.punch_in_long == null"><p class="wordBreak">N/A</p></span>
                        </div>
                        <div v-else><span class="wordBreak">N/A</span></div>
                      </div>
                      <div v-else>
                        <span class="wordBreak">{{'N/A'}}</span>
                      </div>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Punch Out Location
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                      <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
                        <div v-if="checkPermission($route.name, 'View Punch Out Location')">
                          <b-button
                          size="sm"
                          variant="primary"
                          class="ml-0"
                          v-if="items.item.punch_out_lat != null && items.item.punch_out_long != null"
                          @click="openLocation(items.item,'out')"
                          >
                            <feather-icon icon="MapPinIcon"/>
                          </b-button>
                        
                          <span v-if="items.item.punch_out != null && items.item.punch_out_lat == null && items.item.punch_out_long == null"><p class="wordBreak">N/A</p></span>

                          <!-- <p class="wordBreak">{{ items.item.item.punch_out_facial | capitalize}}</p> -->
                        </div>
                        <div v-else><span class="wordBreak">N/A</span></div>
                      </div>
                      <div v-else>
                        <span class="wordBreak">{{'N/A'}}</span>
                      </div>
										</td>

                    	<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  OT Rate
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <div v-if="update == 'disabled'">

                          <span class="wordBreak">S$ {{items.item.ot_rate}}</span>
                        </div>
                        <div v-else>
                          <b-form-input placeholder="" v-model="items.item.ot_rate" @change="updateOTRate(items.index)" autocomplete="off" />
                        </div>
										</td>

									</tr>

                  <tr class="mb-1">
										<th class="pb-50" style="width: 10%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												  Total Working Hours
												</h6>
											<!--  <small>Monthly Sales</small> -->
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                       <div v-if="items.item.status_id && items.item.attendance_status == 'Present'">
           
                          <span class="wordBreak">{{items.item.total_hour}}</span>
                        </div>
                        <div v-else>
                          <span class="wordBreak">{{'00:00'}}</span>
                        </div>
										</td>

										<th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												   Remarks
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <span class="wordBreak">{{items.item.remarks | capitalize}}</span>
										</td>

                    <th class="pb-50" style="width: 5%">
											<div class="d-flex align-items-center">
									
											<div class="ml-1">
												<h6 class="mb-0 mediumFonts">
												   Day(s) Count
												</h6>
											
											</div>
											</div>
										</th>
										<td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                        <span class="wordBreak">{{ items.item.day_count }}</span>
										</td>

									</tr>

									<tr class="mb-1">
										<th class="pb-50" style="width: 16%">
										<b-button
											v-ripple.400="'rgba(113, 102, 240, 0.15)'"
											variant="outline-secondary"
											@click="items.toggleDetails"
											size="sm"
											class="ml-1"
											>
											Hide Detail
											</b-button>
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
										<th class="pb-50" style="width: 16%">
										</th>
										<td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
											
										</td>
									</tr>
								</table>
							</b-col>

							</b-row>
						</div>

				</template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal id="image-modal" ref="image-modal" centered :title="img_title" hide-footer :no-close-on-backdrop="false">
      
      <template>
            <!-- <b-img-lazy v-bind="mainProps" :src="img" fluid class="w-100')"/> -->

            <center>

              <div id="pluswrap" v-if="isLoadingImg">
                <div class="plus">
                  <b-spinner
                    style="width: 3rem; height: 3rem;"
                    class="mr-1"
                    label="Large Spinner"
                    variant="warning"
                  />
                </div>
              </div>
              

              <img v-show="isLoaded" :src="img" alt="Profile Image" style="max-width: 460px;height: auto" @load="isLoadingImg = false; isLoaded = true">

            </center>

      </template>
   
    </b-modal>

    <b-modal
        id="mark-attendance"
        ref="mark-attendance"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Mark Attendance"
        @ok="updateAttendance"
        no-close-on-backdrop
        size="lg"
      >

        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>
          <b-row>
            
            <b-col
              
              md="12"
              class="mb-md-0 mb-2"
              >
              <b-form-group
                label="Date"
                class="required"

              >
                <b-form-datepicker v-model="onDate" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="todayAbsentUsers"/>


              </b-form-group>
            </b-col>
            <b-col md="12">
              
              <b-form-group
                label="User"
                class="required"

              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="attendance_users"
                  label="full_name"
                  class="w-100"
                  placeholder="Select User"
                  :clearable="false"
                  v-model="user"
                />
                  
              </b-form-group>
            </b-col>
            
            <b-col md="12" class="mb-1" v-if="user != null">

              <b-form-group
                label="Attendance Status"
                class="required"

              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="attendance_statuses"
                  label="title"
                  class="w-100"
                  placeholder="Select Status"
                  v-model="attendance_type"
                  @input="resetPunchInPunchOut()"
                  :clearable="false"
                />

              </b-form-group>
            </b-col>

            <b-col md="12" class="mb-1" v-if="user != null && checkPermission($route.name, 'Show Mode In Create Attendance')">
              <b-form-group
                label="Attendance Mode"
                class="required"

              >
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="attendance_modes"
                    class="w-100"
                    label="title"
                    placeholder="Select Attendance Mode"
                    v-model="user_attendance_mode"
                    :clearable="false"
                    @input="resetUserImages()"

                  />
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="attendance_type != null && attendance_type.title == 'Present' && user != null">

              <b-form-group
                label="Punch In Time"
                class="required"

              >

                <date-picker 
                  v-model="punch_in_time" 
                  format="DD MMM YYYY HH:mm" 
                  type="datetime"
                  placeholder="Select"
                  valueType="format"
                  :clearable=true
                  :confirm=true
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  :disabled-date="disabledpunchInDate"
                  >
                  
                </date-picker>
              </b-form-group>

            </b-col>

            <b-col md="6" v-if="attendance_type != null && attendance_type.title == 'Present' && user != null">

              <b-form-group
                label="Punch Out Time"
                class=""

              >

                <date-picker 
                  v-model="punch_out_time" 
                  format="DD MMM YYYY HH:mm" 
                  type="datetime"
                  placeholder="Select"
                  valueType="format"
                  :clearable=true
                  :confirm=true
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  :disabled-time="disabledpunchOutDate"
                  :disabled-date="disabledpunchOutDate"
                  >
                  
                </date-picker>
              </b-form-group>
            </b-col>

            <b-col md="6" v-if="attendance_type != null && attendance_type.title == 'Present' && user != null && user_attendance_mode.key == 'camera'">

              <b-form-group
                label="Punch In Image"
                :class="punch_in_time != null ? 'required' : ''"

              >

                <b-form-file
                    id="extension1"
                    ref="punch-in-file-input"
                    accept=".png, .jpg, .jpeg"
                    @change="punchInFileUpdate"
                  />

                
              </b-form-group>

            </b-col>

            <b-col md="6" v-if="attendance_type != null && attendance_type.title == 'Present' && user != null && user_attendance_mode.key == 'camera'">

              <b-form-group
                label="Punch Out Image"
                :class="punch_out_time != null ? 'required' : ''"

              >

                <b-form-file
                  id="extension2"
                  ref="punch-out-file-input"
                  accept=".png, .jpg, .jpeg"
                  @change="punchOutFileUpdate"
                />

                
              </b-form-group>
            </b-col>
            
            
          </b-row>
        </b-form>
    </b-modal>

    <b-modal id="location-modal" ref="location-modal" centered title="Current Location" hide-footer :no-close-on-backdrop="false" size="lg" @hide="resetModal">
      
      <template>
            <gmap-map
                ref="googleMap"
                :center="center"
                :zoom="zoom"
                :options="{scrollwheel: true}"

                style="width:760px;  height: 500px; overflow: auto"
              >
                
                  <gmap-marker
                    :key="index"
                    class="markerCricle"
                    v-for="(m, index) in latlongMarkers"
                    :position="m.position"
                    :icon="m.icon"
            
                  ></gmap-marker>

                  <!-- <gmap-info-window
                      :options="infoOptions"
                      :position="infoWindowPos"
                      :opened="infoWinOpen"
                      @closeclick="infoWinOpen=false"
                    >
                      <div v-html="infoContent"></div>
                  </gmap-info-window> -->

            </gmap-map>
      </template>
   
    </b-modal>

    <b-modal
        id="exportModel"
        ref="exportModel"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Export Individual"
        @ok="exportFile"
        no-close-on-backdrop
      >

        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>

          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2">
              <!-- <label>Status</label> -->
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allUsers"
                label="full_name"
                class="w-100"
                placeholder="Select User"
                v-model="selectuser"
                :clearable="false"
              />
            </b-col>
          </b-row>
          


        </b-form>
      </b-modal>


      <b-modal
        id="export"
        ref="export"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Export PDF"
        @ok="exportPdf"
        no-close-on-backdrop
      >

        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>

          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2">

              <b-form-group
                label="Report Format"
                class="required"
              > 

                <div class="demo-inline-spacing">
                  <b-form-radio
                    class="mb-0 mt-0"
                    name="some-radios3"
                    value="single"
                    v-model="generate_pdf"
                  >
                    Single Report
                  </b-form-radio>

                  <b-form-radio
                    class="mb-0 mt-0"
                    name="some-radios3"
                    value="byDate"
                    v-model="generate_pdf"
                  >
                    Multiple Report
                  </b-form-radio>
                </div>
              </b-form-group>


            </b-col>
              
              
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2 mt-1">
              <b-form-group
                label="Record Type"
                class="required"
              > 

                <div class="demo-inline-spacing">
                  <b-form-radio
                    class="mb-0 mt-0"
                    name="some-radios99"
                    value="all"
                    v-model="generate_pdf_type"
                  >
                    All Records 
                  </b-form-radio>

                  <b-form-radio
                    class="mb-0 mt-0"
                    name="some-radios99"
                    value="present"
                    v-model="generate_pdf_type"
                  >
                    Present Records
                  </b-form-radio>
                </div>
              </b-form-group>


            </b-col>
              
              
          </b-row>
        </b-form>
      </b-modal>

      <b-modal
        id="remarksModel"
        ref="remarksModel"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Add Remarks"
        @hidden="resetRemarks"
        @ok="updateRemarks"
        no-close-on-backdrop
      >

        <b-alert
        v-model="popshowDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>

        <b-form>

          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2">

              <b-form-group
                label="Remarks"
                class="required"
              > 
              
                <b-form-textarea
                  placeholder="Enter Remarks"
                  rows="3"
                  v-model="remarks"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-md-0 mb-2">

              <b-form-group
                label="OT Rate"
                class=""
              >
                <b-form-input
                placeholder="Enter OT Rate"
                v-model="ot_rate"
                autocomplete="off"
              />
              </b-form-group>
            </b-col>
          </b-row>

        </b-form>
      </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup,BImgLazy,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BCardBody,BFormSelectOption,BFormSelectOptionGroup,BFormSelect,VBTooltip,BFormTextarea,BForm,BFormRadio,BFormDatepicker,BBreadcrumb,BFormFile,BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { GET_API,POST_API } from "../../../store/actions.type"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from "moment-timezone";
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    DatePicker,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
    VueApexCharts,
    BFormTextarea,
    BAlert,
    BFormGroup,
    BForm,
    flatPickr,
    BFormRadio,
    BFormDatepicker,
    BBreadcrumb,
    BImgLazy,
    BFormFile,
    BSpinner
    
  },
  directives: {
    'b-tooltip': VBTooltip,
    
  },
  data() {
    return {

        tableColumns: [
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '15%'}},
            { key: 'full_name', label: 'Employee Name', sortable: false , thStyle:  {width: '15%'}},
            { key: 'role', label: 'Account Type', sortable: false , thStyle:  {width: '15%'}},

            { key: 'shift', label: 'Shift', sortable: false , thStyle:  {width: '15%'}},

            // { key: 'day_count', label: 'Day(s) Count', sortable: false , thStyle:  {width: '5%'}},
            
            { key: 'status_id', label: 'Status', sortable: false , thStyle:  {width: '14%'}},

            { key: 'date', label: 'Date', sortable: true , thStyle:  {width: '15%'}},
            // { key: 'punch_in', label: 'Punch In Time', sortable: true , thStyle:  {width: '7%'}},
            // { key: 'punch_in_mode', label: 'Punch In Mode', sortable: true , thStyle:  {width: '5%'}},
            // { key: 'punch_in_image', label: 'Punch In Image', sortable: false , thStyle:  {width: '5%'}},
            // { key: 'punch_in_location', label: 'Punch In Location', sortable: false , thStyle:  {width: '5%'}},
            // { key: 'punch_out', label: 'Punch Out Time', sortable: true , thStyle:  {width: '7%'}},
            // { key: 'punch_out_mode', label: 'Punch Out Mode', sortable: true , thStyle:  {width: '5%'}},
            // { key: 'punch_out_image', label: 'Punch Out Image', sortable: false , thStyle:  {width: '5%'}},
            // { key: 'punch_out_location', label: 'Punch Out Location', sortable: false , thStyle:  {width: '5%'}},
            // { key: 'total_hour', label: 'Total Working Hours', sortable: true , thStyle:  {width: '5%'}},
            
            // { key: 'ot_rate', label: 'OT Rate', sortable: true , thStyle:  {width: '5%'}},
            // { key: 'remarks', label: 'Remarks', sortable: true , thStyle:  {width: '6%'}},
            
            { key: 'actions', label: 'Actions', sortable: false , thStyle:  {width: '10%'}},
            

          ],
        tableColumnsGroup: [
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '5%'}},
            { key: 'full_name', label: 'Employee Name', sortable: false , thStyle:  {width: '6%'}},
            { key: 'role', label: 'Account Type', sortable: false , thStyle:  {width: '5%'}},

            { key: 'shift', label: 'Shift', sortable: false , thStyle:  {width: '5%'}},

            // { key: 'day_count', label: 'Day(s) Count', sortable: false , thStyle:  {width: '5%'}},
            
            { key: 'status_id', label: 'Status', sortable: false , thStyle:  {width: '5%'}},

            { key: 'date', label: 'Date', sortable: true , thStyle:  {width: '5%'}},

            { key: 'group', label: 'Task Group', sortable: true , thStyle:  {width: '8%'}},

            // { key: 'punch_in', label: 'Punch In Time', sortable: true , thStyle:  {width: '6%'}},
            // { key: 'punch_in_mode', label: 'Punch In Mode', sortable: true , thStyle:  {width: '4%'}},
            // { key: 'punch_in_image', label: 'Punch In Image', sortable: false , thStyle:  {width: '4%'}},
            // { key: 'punch_in_location', label: 'Punch In Location', sortable: false , thStyle:  {width: '4%'}},
            // { key: 'punch_out', label: 'Punch Out Time', sortable: true , thStyle:  {width: '6%'}},
            // { key: 'punch_out_mode', label: 'Punch Out Mode', sortable: true , thStyle:  {width: '4%'}},
            // { key: 'punch_out_image', label: 'Punch Out Image', sortable: false , thStyle:  {width: '4%'}},
            // { key: 'punch_out_location', label: 'Punch Out Location', sortable: false , thStyle:  {width: '4%'}},
            // { key: 'total_hour', label: 'Total Working Hours', sortable: true , thStyle:  {width: '5%'}},
            
            // { key: 'ot_rate', label: 'OT Rate', sortable: true , thStyle:  {width: '5%'}},
            // { key: 'remarks', label: 'Remarks', sortable: true , thStyle:  {width: '6%'}},
            
            { key: 'actions', label: 'Actions', sortable: false , thStyle:  {width: '4%'}},
            

          ],
        
        generate_pdf:'single',
        generate_pdf_type:'all',
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : ''/*'id'*/,
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        siteData:null,
        sites:[],
        showDismissibleAlert:false,
        popshowDismissibleAlert:false,
        error_message:'',
        date:moment().tz('Asia/Singapore').format('DD MMM YYYY'),
        shifts:[],
        disabledBefore:moment().tz('Asia/Singapore'),
        users:[],
        user:null,
        /*config:{ 
          mode: 'range',
          dateFormat: "d M Y",
          defaultDate: [new Date(), new Date()]

        },
        rangeDate:moment().tz('Asia/Singapore').format('DD MMM YYYY'),*/

        center: { lat: 1.3521, lng: 103.8198 },
        zoom:14,
        latlongMarkers:[],
        crew:'',
        //allcrews:[],
        dateFilter:'start_end',

        start:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
        end:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
        selectuser:null,
        allUsers:[],
        shift:'',


        months:[
         'January', 
         'February', 
         'March', 
         'April', 
         'May', 
         'June', 
         'July ', 
         'August', 
         'September', 
         'October', 
         'November', 
         'December'
      ],
      month:moment().format('MMMM'),
      year:moment().format('YYYY'),
      yearData:[],
      attendance_date:moment().tz('Asia/Singapore').format('YYYY-MM-DD HH:mm:ss'),

      //New Mark Attendance
      onDate:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      attendance_type:'Present',
      punch_in_time:null,
      punch_out_time:null,
      attendance_users:[],
      attendance_statuses:[],

      update_type:null,
      update:'disabled',
      remarks:'',
      index:null,
      status_changed:null,
      curr_user_punch_in:null,
      ot_rate:null,
      role:'',
      roles:[],
      img:null,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#ffff',
        width: 600,
        height: 500,
      },
      isLoadingImg:true,
      isLoaded:false,

      img_title:null,
      user_attendance_mode:{key:'web',title:'Web'},
      attendance_modes:[
        {
          key:'web',
          title:'Web'
        },{
          key:'camera',
          title:'Camera'
        },
        {
          key:'nfc',
          title:'NFC'
        },
        {
          key:'gps',
          title:'GPS'
        }
      ],

      punch_in_time_image : {
        image:null,
        name:''
      },

      punch_out_time_image: {
        image:null,
        name:''
      }


    }
  },
  methods : {
    /*download(type){
      
        var keyword = this.searchQuery;
        var sortBy = this.sortBy;
        var sortDirection = this.isSortDirDesc;
        var site = this.siteData ? this.siteData._id : "";
        var site_name = this.siteData ? this.siteData.site_name : "";
        var shift = this.shift ? this.shift : "";
        var frequency = this.frequencyData ? this.frequencyData.name : "";

        var shift_name = "";
        this.shifts.forEach(el => {
          if(this.shift == el._id){
            shift_name = el.shift.toUpperCase();
          }
        })

        var role = this.$store.getters.currentUser.role;

        var crew =  this.crew;
        var group =  this.group ? this.group:'';
        var start = this.start ? moment(this.start).format('YYYY-MM-DD') : "";
        var end = this.end ? moment(this.end).format('YYYY-MM-DD') : "";
        var status = this.status ;
        var dateFilter = this.dateFilter;

        var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&shift="+shift+
        "&frequency="+frequency+"&crew="+crew+"&group="+group+"&start="+start+
        "&end="+end+"&status="+status+"&organization="+ this.$store.getters.currentUser.organization+"&role="+role+
        "&dateFilter="+dateFilter+"&report=periodic"+"&site_name="+site_name.toUpperCase()+
        '&name='+this.$store.getters.currentUser.full_name.toUpperCase()+'&shift_name='+shift_name;
        
        var baseurl = "";

        if(type == 'pdf'){
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/attendance-pdf?"+params;
        } else {
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/attendance-excel?"+params;
        }

        window.open(baseurl,'_blank');
    },*/
    excelTest(){
      return this.$store.dispatch(POST_API, {
         data:{
           crew:this.crew,
           start:this.start,
           end:this.end,
           site:this.siteData ? this.siteData._id : null,
           shift:this.shift,
         },
         api: '/api/check-punchout-timesheet'
      })
      .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                var message  = this.$store.getters.getResults.message;
                var data  = this.$store.getters.getResults.data;
                
                
                if (data == 0) {
                    this.excelGenerate();
                }else{

                    Swal.fire({
                      
                      title: 'Are you sure?',
                      text: "Some of the user(s) do not have the punch out record",
                      icon: 'warning',

                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: 'Yes',
                      denyButtonText: 'No',
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        this.excelGenerate();
                      } else if (result.isDenied) {
                        
                        //Swal.fire('Operation Cancelled', '', 'info')
                      }
                    })

                }

                
            }
        });
    },
    excelGenerate(){
      var site = this.siteData ? this.siteData._id : "";
      var site_name = this.siteData ? this.siteData.site_name : null;
      var crew =  this.crew;
      var shift = this.shift ? this.shift : "";
      var start = this.start ? moment(this.start).format('YYYY-MM-DD') : "";
      var end = this.end ? moment(this.end).format('YYYY-MM-DD') : "";

      var params = "site="+site+"&shift="+shift+
        "&site_name="+site_name+
        "&crew="+crew+"&start="+start+
        "&end="+end+"&organization="+ this.$store.getters.currentUser.organization+
        '&name='+this.$store.getters.currentUser.full_name.toUpperCase();

      var baseurl = null;
      
      baseurl = process.env.VUE_APP_SERVER_URL +"/api/attendance-excel?"+params;
      window.open(baseurl,'_blank');
    },

    dataList(){
      return this.$store.dispatch(POST_API, {
         data:{
           page: this.currentPage,
           keyword: this.searchQuery,
           rowsPerPage:this.perPage,
           sortBy:this.sortBy,
           sortDirection:this.isSortDirDesc,
           site:this.siteData ? this.siteData._id : null,
           role:this.$store.getters.currentUser.role,
           om_sites:this.$store.getters.currentUser.om_sites,
           start:this.start,
           end:this.end,
           crew:this.crew,
           shift:this.shift,
           filterrole: this.role,
           //date:this.rangeDate
         },
         api: '/api/timesheet'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.items = this.$store.getters.getResults.data.docs;
              this.totalRecords = this.$store.getters.getResults.data.total;
              this.perPage = this.$store.getters.getResults.data.limit;
              this.from = this.$store.getters.getResults.data.from
              this.to = this.$store.getters.getResults.data.to
              return this.items;
          }
      });
    },
    filterTable(){
      
      this.$refs.refUserListTable.refresh();
    },
    /*updateSite(){

      if (this.siteData != null) {
          
          this.getShift();
      }

      this.filterTable()
    },*/
    getShift(){
      
      return this.$store.dispatch(POST_API, {
           data:{
             site: this.siteData != null ? this.siteData._id : null
           },
           api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                this.shifts = data;
                return this.shifts;
            }
        });
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
            role:this.$store.getters.currentUser.role,
            //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;

              if (this.sites.length == 1 ) {
                  this.siteData = this.sites[0];
                  this.getUsers();
                  this.filterTable();
              }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm',
                attendance_mode:'camera'
              }
              this.sites.unshift(obj);
              
              this.siteData = this.defaultSite(this.sites);
              this.getUsers();
              this.filterTable();
              
              return this.sites;
          }
      });
    },
    disabledDate(date,currentValue){
      /*return date > moment().tz('Asia/Singapore')*/

      /*if (typeof currentValue[0] != 'undefined') {
        return date < moment(currentValue[0]).tz('Asia/Singapore')|| date > moment(currentValue[0]).tz('Asia/Singapore');
      }else{
        return date > moment().tz('Asia/Singapore')
      }*/
      
      if (this.curr_user_punch_in != null) {
        
        return date < moment(this.curr_user_punch_in).tz('Asia/Singapore')|| date > moment(this.curr_user_punch_in).tz('Asia/Singapore');
      }else{
        
        return date > moment().tz('Asia/Singapore')
      }
      

      
    },
    disabledDate2(date,currentValue){
      
      if (this.curr_user_punch_in != null) {
        
        var time = moment(new Date(this.curr_user_punch_in)).format('HH:mm');
        
        if (time == '00:00') {
          return date < moment(new Date(this.curr_user_punch_in)).tz('Asia/Singapore') || date > moment(new Date(this.curr_user_punch_in)).add(1,'days').tz('Asia/Singapore');  
        }else{
          return date < moment(new Date(this.curr_user_punch_in)).subtract(1,'days').tz('Asia/Singapore') || date > moment(new Date(this.curr_user_punch_in)).add(1,'days').tz('Asia/Singapore');
        }
      }else{
        
        return date < moment().tz('Asia/Singapore') || date > moment().tz('Asia/Singapore');

      }
    },
    
    disabledpunchInDate(date){
      return date < moment(this.onDate).tz('Asia/Singapore') || date > moment(this.onDate).tz('Asia/Singapore')
    },
    disabledpunchOutDate(date){
      
      if (this.punch_in_time != null) {
        
        var time = moment(new Date(this.punch_in_time)).format('HH:mm');
        
        if (time == '00:00') {
          return date < moment(new Date(this.punch_in_time)).tz('Asia/Singapore') || date > moment(new Date(this.punch_in_time)).add(1,'days').tz('Asia/Singapore');  
        }else{
          return date < moment(new Date(this.punch_in_time)).subtract(1,'days').tz('Asia/Singapore') || date > moment(new Date(this.punch_in_time)).add(1,'days').tz('Asia/Singapore');
        }
      }else{
        
        return date < moment(this.onDate).tz('Asia/Singapore') || date > moment(this.onDate).tz('Asia/Singapore');

      }
    },
    calHour(index,type){
      
      this.update_type = type;

      /*console.log('aya');*/

      var data = this.items[index];
      var in_time = new Date(data.punch_in);
      var out_time = new Date(data.punch_out);
      


      if ((data.punch_in && data.punch_out) && (out_time >=  in_time) ) {

        /*var hours = parseInt(Math.abs(out_time - in_time) / (1000 * 60 * 60) % 24);
        var minutes = parseInt(Math.abs(out_time.getTime() - in_time.getTime()) / (1000 * 60) % 60);
        var seconds = parseInt(Math.abs(out_time.getTime() - in_time.getTime()) / (1000) % 60);

        if (hours < 10) {
            hours = '0'+ hours
        }

        if (minutes < 10) {
            minutes = '0' + minutes
        }

        if (seconds < 10) {
            seconds = '0' + seconds
        }
        var working_hour = hours +':'+minutes+':'+seconds;*/

        var in_time = moment(new Date(data.punch_in)).format('DD/MM/YYYY HH:mm:ss');
        var out_time = moment(new Date(data.punch_out)).format('DD/MM/YYYY HH:mm:ss');

        var ms = moment(out_time,"DD/MM/YYYY HH:mm:ss").diff(moment(in_time,"DD/MM/YYYY HH:mm:ss"));
        var d = moment.duration(ms);

        var days = d.days();
        var hours = d.hours();
        var minutes = d.minutes();
        var seconds = d.seconds();

        if (days > 0) {
          hours = (days * 24) + hours; 
        }

        if (hours < 10) {
          hours = '0' + hours;
        }

        if (minutes < 10) {
              minutes = '0' + minutes
        }

        if (seconds < 10) {
            seconds = '0' + seconds
        }

        var working_hour = hours +':'+minutes/*+':'+seconds*/;

        this.items[index].total_hour = working_hour
        
        this.updateItem(this.items[index]);
        //update

      }else if((data.punch_in && data.punch_out) && (out_time <  in_time)){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Punch out time should not be less than punch in time',
          showConfirmButton: false,
          timer: 1500
        })

        this.items[index].item.punch_out = null;
      }else{
        this.updateItem(this.items[index]);
      }

    },
    updateItem(item){
      return this.$store.dispatch(POST_API, {
         data:{
            item:item,
            update_type:this.update_type,
         },
         api: '/api/update-timesheet-item'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.popshowDismissibleAlert = true;
          } else {
              this.popshowDismissibleAlert = false;
              var data = this.$store.getters.getResults.data;
              //

              this.filterTable();
          }
      });
    },
    updateShift(index){
      this.updateItem(this.items[index]);
    },
    openModel(){

      this.popshowDismissibleAlert = false;
      
      this.attendance_users = [];
      this.user = null;
      
      this.onDate = this.start == this.end ? moment(this.start).tz('Asia/Singapore').format('YYYY-MM-DD') : moment(this.end).tz('Asia/Singapore').format('YYYY-MM-DD');
      
      this.punch_out_time = null;
      this.punch_in_time = null;

      this.attendance_type = null;

      for (var i = 0; i < this.attendance_statuses.length; i++) {
        if (this.attendance_statuses[i].title == 'Present') {
            this.attendance_type = this.attendance_statuses[i];          
        }
      }

      this.user_attendance_mode = {key:'web',title:'Web'};
      this.punch_in_time_image = {
        image:null,
        name:''
      };
      this.punch_out_time_image = {
        image:null,
        name:''
      }


      
      this.todayAbsentUsers();

      this.$refs['mark-attendance'].show();
    },
    /*getUsers(){

      return this.$store.dispatch(POST_API, {
         data:{
            site:this.siteData ? this.siteData._id : null,
            role_id:this.role,
            role:this.$store.getters.currentUser.role,
            //date:this.date != ''? moment(this.date).format('YYYY-MM-DD') : null
         },
         api: '/api/get-attandance-users-by-date'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.users = data;
              return this.users;
          }
      });
    },*/
    getUsers(){
        if(this.role == ''){
          var role = ['supervisor','crew'];
        }else{
          var role = this.roles.filter(item => {return item._id == this.role});
          role = [role[0].role];
        }
        return this.$store.dispatch(POST_API, {
             data:{
               site:this.siteData ? this.siteData._id : null,
               role:role,
               enable_attendance:'yes'
             },
             api: '/api/all-group-user-by-role'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  //window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  this.users = data;

                  return this.users;
              }
          });
      },
    getUserswithoutRole(){
      return this.$store.dispatch(POST_API, {
         data:{
            site:this.siteData ? this.siteData._id : null,
            
            role:this.$store.getters.currentUser.role,
            //date:this.date != ''? moment(this.date).format('YYYY-MM-DD') : null
         },
         api: '/api/get-attandance-users-by-date'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;

              this.allUsers = [];

              var obj = {
                _id:'all-users',
                full_name:'All Users'
              }

              this.allUsers.push(obj);
              data.forEach(item => {
                this.allUsers.push(item);  
              })


              return this.allUsers;
          }
      });

    },
    updateAttendance(e){
      e.preventDefault()
      
      return this.$store.dispatch(POST_API, {
           data:{
              site:this.siteData._id,
              userId:this.user != null ? this.user._id :'',
              lat: null,
              long: null,
              date:this.onDate,
              punch_in_time:this.punch_in_time,
              punch_out_time:this.punch_out_time,
              attendance_type:this.attendance_type,
              attendance_mode:this.user_attendance_mode,
              punch_in_image:this.punch_in_time_image,
              punch_out_image:this.punch_out_time_image,
           },
           api: '/api/mark-user-attendance-web'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Attendance Created Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    this.filterTable();
                    this.$refs['mark-attendance'].hide();
                });

                
            }
        });
    },
    /*exportPdf(type){

      var sortBy = this.sortBy;
      var sortDirection = this.isSortDirDesc;
      var site = this.siteData ? this.siteData._id : "";
      var role = this.$store.getters.currentUser.role;


      var start = moment(this.start).format('YYYY-MM-DD');
      var end = moment(this.end).format('YYYY-MM-DD');
      var crew = this.crew;
      
      var om_sites = [];

      for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
        om_sites.push(this.$store.getters.currentUser.om_sites[i]);
      }


      var params = "sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+
      "&start="+start+
      "&end="+end+"&organization="+ this.$store.getters.currentUser.organization+"&role="+role+
      "&om_sites="+om_sites.join(',')+"&crew="+crew;
      
      var baseurl = "";
      
      if (type == 'pdf') {
        
        baseurl = process.env.VUE_APP_SERVER_URL +"/api/timesheet-pdf?"+params;
        console.log(baseurl);
      }else{
        
        //baseurl = process.env.VUE_APP_SERVER_URL +"/api/task-status-excel?"+params;

      }

      window.open(baseurl,'_blank');
      
    },*/
    /*openView(url){
      window.open(url,'_blank');
    },*/
    openLocation(item,type){
      

      var markers = [];

      var icon = {
          /*url: null,*/
          shape:shape,
          scaledSize: {width: 28, height: 28},
          labelOrigin: {x: 16, y: -10}
      };

      var shape = {
          coords: [0, 0, 60],
          type: 'circle'
      };


      markers.push({
          'position':
              { 'lat': type == 'in' ? Number(item.punch_in_lat) : Number(item.punch_out_lat), 
               'lng':type == 'in' ? Number(item.punch_in_long) : Number(item.punch_out_long),
              },
              icon:icon,

              shape:shape

      })
      console.log(markers);

      this.latlongMarkers = markers;

      this.center = markers[0].position

      
      setTimeout(()=>{
          this.center = this.latlongMarkers[0].position
      }, 500);

      this.$refs['location-modal'].show();
    },

    resetModal(){

      this.latlongMarkers = [];

      this.$refs['location-modal'].hide();
    },
    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Attendance Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                        },
                    api:"/api/delete-attendance",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },

    removePunchOutItem(id){
      var msg = 'Are you sure want to remove punch out for this record?';
      var message='Punch out removed Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                        },
                    api:"/api/remove-punch-out-attendance",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },

    updateSite(){
      this.users = [];
      this.role = '';
      this.crew = '';
      this.shifts = [];
      this.shift = '';

      this.update = "disabled";

      this.getUsers();

      if (this.siteData && this.siteData._id != 'all-site') {
        this.getShift();
      }

      this.filterTable();
    },
    /*allCrews(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.siteData ? this.siteData._id : null,
           },
           api: '/api/get-attandance-users-by-date'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                //window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.allcrews = data;
                return this.allcrews;
            }
        });
    },*/
    updateDateType(){
      if (this.dateFilter == 'month_year') {

        this.start = moment().tz('Asia/Singapore').startOf('month').format('YYYY-MM-DD');
        this.end = moment().tz('Asia/Singapore').endOf('month').format('YYYY-MM-DD');

      }else{

        this.start = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
        this.end = moment().tz('Asia/Singapore').format('YYYY-MM-DD');

      }

      this.filterTable();

    },
    getDate(){
      var date = null;
      if (this.month == null && this.year == null) {
        //date = moment.utc().startOf('month').format('YYYY-MM-DD');

        this.start = null;
        this.end = null;

      }else if(this.month == null && this.year != null){

        date = moment.utc().startOf('month').format('MM-DD');
        var year = this.year;
        date =  year.toString() + '-'+ date;
        date = moment.utc(date).startOf('year').format('YYYY-MM-DD');

        this.start = date;
        this.end = moment(date).endOf('year').format('YYYY-MM-DD');

      }else if(this.month != null && this.year == null){

        var year = moment.utc().startOf('month').format('YYYY');
        date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');

        this.start = date;
        this.end = moment(date).endOf('month').format('YYYY-MM-DD');

      }else{

        var year = this.year.toString();

        date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
        date = moment.utc(date).startOf('month').format('YYYY-MM-DD');

        this.start = date;
        this.end = moment(date).endOf('month').format('YYYY-MM-DD');

      }

      /*this.start = date;
      this.end = moment(date).endOf('month').format('YYYY-MM-DD');*/
      this.filterTable();

    },

    calYear(){
      var year = moment().format('YYYY');
      var yearData = [];
      var start = parseInt(year) - 2;
      var end =  parseInt(year) + 5;

      for (var i = start ; i <= end; i++) {
        yearData.push(i);
      }

      this.yearData = yearData;
    },
    disabledDates(){
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      }else{
        console.log('aa');
        return moment().format('YYYY-MM-DD');

      }
    },
    updateStart(){
      this.end = moment(this.start).tz('Asia/Singapore').format('YYYY-MM-DD');
      this.filterTable();

    },
    openExportModel(){

      this.popshowDismissibleAlert = false;
      this.selectuser = null;
      this.allUsers = [];
      this.getUserswithoutRole();
      this.$refs['exportModel'].show();
    },
    exportFile(bvModalEvt){
      bvModalEvt.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             crew:this.selectuser == null ? '' : this.selectuser._id,
             start:this.start,
             end:this.end,
             site:this.siteData ? this.siteData._id : null,
             shift:this.shift,
           },
           api: '/api/export-individual-pdf'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                var message  = this.$store.getters.getResults.message;
                var data  = this.$store.getters.getResults.data;
                
                var link = document.createElement("a");
                link.download = data.name;
                link.href = data.file;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);


                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: message,
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    
                    this.$refs['exportModel'].hide();
                });

                
            }
        });
    },
    exportPdf(evt){
      
      evt.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             crew:this.crew,
             start:this.start,
             end:this.end,
             site:this.siteData ? this.siteData._id : null,
             generate_pdf:this.generate_pdf,
             generate_pdf_type:this.generate_pdf_type,
             shift:this.shift,
           },
           api: '/api/check-punchout-timesheet'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                var message  = this.$store.getters.getResults.message;
                var data  = this.$store.getters.getResults.data;
                
                
                if (data == 0) {
                    this.pdfGenerate();
                }else{

                    Swal.fire({
                      
                      title: 'Are you sure?',
                      text: "Some of the user(s) do not have the punch out record",
                      icon: 'warning',

                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: 'Yes',
                      denyButtonText: 'No',
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        this.pdfGenerate();
                      } else if (result.isDenied) {
                        this.$refs['export'].hide();
                        //Swal.fire('Operation Cancelled', '', 'info')
                      }
                    })

                }

                
            }
        });
      
    },
    //Code for Generate using Digital Ocean
    /*pdfGenerate(){
      return this.$store.dispatch(POST_API, {
           data:{
             crew:this.crew,
             start:this.start,
             end:this.end,
             site:this.siteData ? this.siteData._id : null,
             generate_pdf:this.generate_pdf,
             shift:this.shift,
           },
           api: '/api/timesheet-pdf'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                var message  = this.$store.getters.getResults.message;
                var data  = this.$store.getters.getResults.data;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: message,
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    
                    this.$refs['export'].hide();
                    window.open(data,'_blank');
                });

                
            }
        });
    },*/

    shortfallTest(){
      return this.$store.dispatch(POST_API, {
         data:{
           crew:this.crew,
           start:this.start,
           end:this.end,
           site:this.siteData ? this.siteData._id : null,
           shift:this.shift,
         },
         api: '/api/check-punchout-timesheet'
      })
      .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                var message  = this.$store.getters.getResults.message;
                var data  = this.$store.getters.getResults.data;
                
                if (data == 0) {
                    this.shortfallReport();
                }else{
                    Swal.fire({
                      title: 'Are you sure?',
                      text: "Some of the user(s) do not have the punch out record",
                      icon: 'warning',
                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: 'Yes',
                      denyButtonText: 'No',
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        this.shortfallReport();
                      } else if (result.isDenied) {
                        //Swal.fire('Operation Cancelled', '', 'info')
                      }
                    })

                }

                
            }
        });
    },

    shortfallReport(){
      var params = 'crew='+this.crew+'&start='+this.start+'&end='+this.end+'&site='+this.siteData._id+'&generate_pdf='+this.generate_pdf+'&shift='+this.shift+'&organization='+this.$store.getters.currentUser.organization+'&role='+this.role;

      var baseurl = null;
      console.log(baseurl);
      //dynamic word for new code accept all site to generate shortfall
      //without dynamic old code only made for novena site.
      baseurl = process.env.VUE_APP_SERVER_URL +"/api/shortfall-dynamic-report-pdf?"+params;
      window.open(baseurl,'_blank'); 
    },  
    
    pdfGenerate(){

     var params = 'crew='+this.crew+'&start='+this.start+'&end='+this.end+'&site='+this.siteData._id+'&generate_pdf='+this.generate_pdf+'&shift='+this.shift+'&organization='+this.$store.getters.currentUser.organization+'&role='+this.role+'&generate_pdf_type='+this.generate_pdf_type;

     var baseurl = null;
      
     baseurl = process.env.VUE_APP_SERVER_URL +"/api/timesheet-pdf?"+params;
     window.open(baseurl,'_blank'); 
     this.$refs['export'].hide();
    },  
    exportPdfModel(){
      
      this.popshowDismissibleAlert = false;
      this.generate_pdf = 'single';
      this.generate_pdf_type = 'all';
      this.$refs['export'].show();
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:null,
        text:'Attendance',
        active:true
      }];
      return item;
    },
    resetPunchInPunchOut(){
      this.user_attendance_mode = {key:'web',title:'Web'};
      this.punch_in_time = null;
      this.punch_out_time = null;
    },
    todayAbsentUsers(){
      
      this.user = null;
      return this.$store.dispatch(POST_API, {
         data:{
            site:this.siteData ? this.siteData._id : null,
            date:this.onDate
         },
         api: '/api/get-attendance-users-not-added'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.attendance_users = data;

              return this.attendance_users;
          }
      });
    },
    changeUpdate(){
      if (this.update == 'disabled') {

        this.update = 'enabled';

      }else{
        this.update = 'disabled';
        this.$refs.refUserListTable.refresh();
      }

      return this.update;
    },
    changeStatusReset(i,e){
      
      this.popshowDismissibleAlert = false;

      this.update_type = null;
      this.index = i;
      this.status_changed = 'yes';
      
      var remarks = this.items[i].remarks;
      var ot_rate = '0';
      
      this.remarks = remarks;
      this.ot_rate = ot_rate;
      this.$refs['remarksModel'].show();      
    },

    getAttendanceStatus(){
      return this.$store.dispatch(GET_API, {
         data:{
            
         },
         api: '/api/get-attendance-status'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.attendance_statuses = data;

              return this.attendance_statuses;
          }
      });
    },
    updateOTRate(i){
      this.updateItem(this.items[i]);
    },
    updateRemarks(e){
      //console.log('yop')
      e.preventDefault();

      var status_title = this.attendance_statuses.filter((item)=>{return item._id == this.items[this.index].status_id })

      if (status_title.length > 0 && status_title[0].title == 'Present') {
        this.items[this.index].punch_in = moment(this.items[this.index].date + ' '+moment().tz('Asia/Singapore').format('HH:mm')).format('YYYY-MM-DD HH:mm');
        this.items[this.index].punch_in_mode = 'web';
      }else{
        this.items[this.index].punch_in = null;
        this.items[this.index].punch_in_mode = null;
      }

      //this.items[this.index].punch_in = null;
      this.items[this.index].punch_out = null;
      this.items[this.index].total_hour = null;
      //this.items[this.index].punch_in_mode = null;
      this.items[this.index].punch_out_mode = null;

      this.items[this.index].punch_in_image = null;
      this.items[this.index].punch_in_facial = 'no';
      this.items[this.index].punch_in_lat = null;
      this.items[this.index].punch_in_long = null;
      this.items[this.index].punch_out_image = null;
      this.items[this.index].punch_out_facial = 'no';
      this.items[this.index].punch_out_lat = null;
      this.items[this.index].punch_out_long = null;
      this.items[this.index].ot_rate = this.ot_rate;
      this.items[this.index].remarks = this.remarks;
      this.items[this.index].generate_group = 'dynamic';
      this.items[this.index].group = '';
      
      return this.$store.dispatch(POST_API, {
         data:{
            item:this.items[this.index],
            update_type:this.update_type,
            status_changed:'yes'
         },
         api: '/api/update-timesheet-item'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.popshowDismissibleAlert = true;
          } else {
              this.popshowDismissibleAlert = false;
              var data = this.$store.getters.getResults.data;
              this.$refs['remarksModel'].hide();

              Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    
                    this.filterTable();
                });
              
          }
      });      
      
    },
    resetRemarks(){
      console.log('aaaaaaa')
      this.filterTable();
    },
    getRoles(){
      return this.$store.dispatch(POST_API, {
         data:{
            role_type:['supervisor','crew']
         },
         api: '/api/get-roles-by-type'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.roles = data;

              return this.roles;
          }
      });
    },
    updateRole(){
      this.getUsers();
      this.crew = '';
      this.filterTable();
    },
    updateTaskGroup(item){

      return this.$store.dispatch(POST_API, {
         data:{
            generate_group:item.generate_group,
            group:item.group,
            id:item._id
         },
         api: '/api/update-task-group-attendance'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              
          }
      });
    },
    openView(img,title){
      this.isLoadingImg = true;
      this.isLoaded = false;
      
      this.img_title = title;
      this.img = img+'?'+ new Date();
      this.$refs['image-modal'].show();

    },
    resetUserImages(){
      this.punch_in_time_image = {
        image:null,
        name:''
      };

      this.punch_out_time_image = {
        image:null,
        name:''
      };
      this.punch_in_time = null;
      this.punch_out_time = null;
      this.$refs['punch-in-file-input'].reset();
      this.$refs['punch-out-file-input'].reset();
    },
    
    punchInFileUpdate(event){
      var input = event.target;
      var files = event.target.files
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg only',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['punch-in-file-input'].reset()

          } else {

              reader.onload = (e) => {
                  this.punch_in_time_image.image = e.target.result;
                  this.punch_in_time_image.name = image.name;
            }
          }
      }
    },
    punchOutFileUpdate(event){
      var input = event.target;
      var files = event.target.files
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg only',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['punch-out-file-input'].reset()

          } else {

              reader.onload = (e) => {
                  this.punch_out_time_image.image = e.target.result;
                  this.punch_out_time_image.name = image.name;
            }
          }
      }
    },

    getCapitalize(value){
        if (value) {
            value = value.replace(/_/g, ' ');

            value = value.split(' ');

            var arr = [];
            value.forEach(item => {
                arr.push(item.charAt(0).toUpperCase() + item.slice(1))
            })

            return arr.join(' ');
        }else{
            return 'N/A';
        }
    },
    resetall(){
      this.users = [];
      this.shifts = [];
      this.role = '';
      this.crew = '';
      this.shift = '';
      this.dateFilter = 'start_end';
      this.month = moment().format('MMMM');
      this.year = moment().format('YYYY');
      this.start = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
      this.end = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
      this.allSites();

    }

  },

  mounted(){
    this.allSites();
    this.calYear();
    this.getAttendanceStatus();
    this.getRoles();

  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
